function NewQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "function_score": {
        "query": {
          "bool": {
            "should": [
              {
                "match": {
                  "label.strip_whitespace": {
                    "query": searchTerm,
                    "boost": 100,
                    "_name": "exact match 1 * score_multiply_100"
                  }
                }
              },
              {
                "bool": {
                  "must": [
                    {
                      "multi_match": {
                        "query": searchTerm,
                        "fields": ["label.transform*"]
                      }
                    }
                  ],
                  "boost": 80,
                  "_name": "transform exact match * score_multiply_80"
                }
              },
              {
                "bool": {
                  "must": [
                    {
                      "multi_match": {
                        "query": searchTerm,
                        "fields": ["label"]
                      }
                    }
                  ],
                  "boost": 60,
                  "_name": "exact match 2 * score_multiply_60"
                }
              },
              {
                "match": {
                  "label.first_3_chars": {
                    "query": searchTerm,
                    "boost": 20,
                    "_name": "match first 3 chars * score_multiply_20"
                  }
                }
              },
              {
                "bool": {
                  "must": [
                    {
                      "multi_match": {
                        "query": searchTerm,
                        "fields": ["label.3chars_transform*"]
                      }
                    }
                  ],
                  "boost": 10,
                  "_name": "match first 3 chars with transform * score_multiply_10"
                }
              },
              {
                "bool": {
                  "must": [
                    {
                      "multi_match": {
                        "query": searchTerm,
                        "fields": [
                          "label.first_char"
                        ]
                      }
                    },
                    {
                      "multi_match": {
                        "query": searchTerm,
                        "fields": [
                          "label.last_3_chars"
                        ]
                      }
                    }
                  ],
                  "boost": 15,
                  "_name": "First Letter transform & Last 3 * score_multiply_15"
                }
              },
              {
                "bool": {
                  "must": [
                    {
                      "multi_match": {
                        "query": searchTerm,
                        "fields": [
                          "label.first_char"
                        ]
                      }
                    },
                    {
                      "multi_match": {
                        "query": searchTerm,
                        "fields": [
                          "label.last_2_chars"
                        ]
                      }
                    }
                  ],
                  "boost": 10,
                  "_name": "First Letter transform & Last 2 * score_multiply_10"
                }
              },
              {
                "wildcard": {
                  "label": {
                    "value": `*${searchTerm}*`,
                    "_name": "wildcard * score_multiply_5",
                    "boost": 5
                  }
                }
              },
              {
                "multi_match": {
                  "query": searchTerm,
                  "fields": [ "label.last_3_chars" ],
                  "_name": "last 3 * score_multiply_2",
                  "boost": 2
                }
              }
            ],
            "filter": [
              {
                "term": {
                  "available": "true"
                }
              }
            ],
            "minimum_should_match": 1
          }
        },
        "functions": [
          {
            "filter": { "match": { "supplier_id": "19" } },
            "weight": 1.5
          }
        ],
        "max_boost": 42,
        "score_mode": "max",
        "boost_mode": "multiply"
      }
    },
    "_source": ["label"],
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "sort": [ "_score" ],
    "track_total_hits": true
  };
}
export default NewQuery;
