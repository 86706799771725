function OldQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "bool": {
        "should": [
          {
            "match": {
              "label.first_3_chars_mapping_field": searchTerm
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.2_min_4_max_edge_ngram_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.last_3_chars_mapping_field": searchTerm
                  }
                }
              ],
              "boost": 2
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.2_min_4_max_edge_ngram_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.2nd_part_first_two_chars_mapping_field": searchTerm
                  }
                }
              ]
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_1_char_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.last_3_chars_mapping_field": searchTerm
                  }
                }
              ],
              "boost": 2.5
            }
          },
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "label",
                "label.no_numbers_mapping_field"
              ],
              "type": "best_fields",
              "analyzer": "multi_word_search_prefix_style_analyzer"
            }
          },
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "label",
                "label.no_numbers_mapping_field"
              ],
              "type": "best_fields",
              "analyzer": "multi_word_number_sub_search_prefix_style_analyzer"
            }
          },
          {
            "match": {
              "label.first_2_chars_mapping_field": {
                "query": searchTerm,
                "boost": 2
              }
            }
          },
          {
            "match": {
              "label.first_2_chars_mapping_field": {
                "query": searchTerm,
                "boost": 2,
                "analyzer": "first_2_chars_prefix_style_index_analyzer"
              }
            }
          },
          {
            "match": {
              "label": {
                "query": searchTerm,
                "boost": 1,
                "analyzer": "base_trigram_prefix_style_search_analyzer"
              }
            }
          },
          {
            "dis_max": {
              "queries": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label",
                      "label.no_numbers_mapping_field",
                      "label.mapped_single_numbers1_mapping_field",
                      "label.mapped_single_numbers2_mapping_field"
                    ],
                    "type": "best_fields",
                    "analyzer": "base_search_prefix_style_analyzer"
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label",
                      "label.mapped_single_numbers1_mapping_field",
                      "label.mapped_single_numbers2_mapping_field"
                    ],
                    "type": "best_fields",
                    "analyzer": "short_ngram_prefix_style_search_analyzer",
                    "boost": 1
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label",
                      "label.mapped_single_numbers1_mapping_field",
                      "label.mapped_single_numbers2_mapping_field"
                    ],
                    "type": "best_fields",
                    "analyzer": "base_trigram_prefix_style_search_analyzer"
                  }
                },
                {
                  "match": {
                    "label": {
                      "query": searchTerm,
                      "analyzer": "single_char_mapping_prefix_style_search_analyzer",
                      "boost": 1
                    }
                  }
                },
                {
                  "match": {
                    "label": {
                      "query": searchTerm,
                      "analyzer": "single_char_mapping_long_ngram_prefix_style_search_analyzer",
                      "boost": 1
                    }
                  }
                },
                {
                  "match": {
                    "label.mapped_multicharacters_mapping_field": {
                      "query": searchTerm,
                      "analyzer": "base_search_prefix_style_analyzer"
                    }
                  }
                },
                {
                  "match": {
                    "label.deduplicated_chars_mapping_field": {
                      "query": searchTerm,
                      "boost": 1.5
                    }
                  }
                },
                {
                  "match": {
                    "label": {
                      "query": searchTerm,
                      "analyzer": "strip_vowels_search_prefix_style_analyzer",
                      "boost": 1
                    }
                  }
                }
              ]
            }
          },
          {
            "match": {
              "supplier_id": {
                "query": 19,
                "boost": 8,
                "lenient": true,
                "operator": "OR",
                "zero_terms_query": "all"
              }
            }
          }
        ],
        "must": [],
        "filter": [
          {
            "term": {
              "available": true
            }
          }
        ]
      }
    },
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "sort": [
      "_score"
    ],
    "track_total_hits": true
  };
}

export default OldQuery;
