function NewQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "match_all": {}
    },
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "track_total_hits": true
  };
}

export default NewQuery;
