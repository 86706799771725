import React from "react";
import {Routes, Route, Link} from "react-router-dom";
import "./index.css";
import App from "./components/App";
import {default as CurrentOldQuery} from "./current/OldQuery";
import {default as FourCharNewQuery} from "./current/4char/NewQuery";
import {default as ThreeCharNewQuery} from "./current/3char/NewQuery";
import {default as FiveCharNewQuery} from "./current/5char/NewQuery";

import {default as DatelessOldQuery} from "./dateless/OldQuery";
import {default as DatelessNewQuery} from "./dateless/NewQuery";

import {default as PrefixOldQuery} from "./prefix/OldQuery";
import {default as PrefixTwoCharNewQuery} from "./prefix/2char/NewQuery";
import {default as PrefixThreeCharNewQuery} from "./prefix/3char/NewQuery";
import {default as PrefixFourCharNewQuery} from "./prefix/4char/NewQuery";
import {default as PrefixFiveCharNewQuery} from "./prefix/5char/NewQuery";
import {default as PrefixSixCharNewQuery} from "./prefix/6char/NewQuery";
import {default as PrefixSevenCharNewQuery} from "./prefix/7char/NewQuery";

const Root = () => {
    return (
        <div className="app-container">
            <div>
                <nav className="app-navigation">
                    <ul>
                        <li className="dropdown">
                            <span>Prefix</span>
                            <div className="dropdown-content">
                                <Link to="/prefix-2char">2 Chars</Link>
                                <Link to="/prefix-3char">3 Chars</Link>
                                <Link to="/prefix-4char">4 Chars</Link>
                                <Link to="/prefix-5char">5 Chars</Link>
                                <Link to="/prefix-6char">6 Chars</Link>
                                <Link to="/prefix-7char">7 Chars</Link>
                            </div>
                        </li>
                        <li className="dropdown">
                            <span>Current</span>
                            <div className="dropdown-content">
                                <Link to="/current-3char">3 Chars</Link>
                                <Link to="/">4 Chars</Link>
                                <Link to="/current-5char">+5 Chars</Link>
                            </div>
                        </li>
                        <li>
                            <Link to="/dateless">Dateless</Link>
                        </li>
                    </ul>
                </nav>
                <h1 className="app-title">Elasticsearch Viewer</h1>
            </div>
            <Routes>
                <Route
                    path="/"
                    element={
                        <App
                            onOldQuery={CurrentOldQuery}
                            onNewQuery={FourCharNewQuery}
                            endpointName="endpoint"
                            headerText="4 Chars Queries"
                        />
                    }
                />
                <Route
                    path="/current-3char"
                    element={
                        <App
                            onOldQuery={CurrentOldQuery}
                            onNewQuery={ThreeCharNewQuery}
                            endpointName="3char_endpoint"
                            headerText="1-2-3 Chars Queries"
                        />
                    }
                />
                <Route
                    path="/current-5char"
                    element={
                        <App
                            onOldQuery={CurrentOldQuery}
                            onNewQuery={FiveCharNewQuery}
                            endpointName="5char_endpoint"
                            headerText="+5 Chars Queries"
                        />
                    }
                />
                <Route
                    path="/dateless"
                    element={
                        <App
                            onOldQuery={DatelessOldQuery}
                            onNewQuery={DatelessNewQuery}
                            endpointName="dateless"
                            headerText="Dateless"
                        />
                    }
                />
                <Route
                    path="/prefix-2char"
                    element={
                        <App
                            onOldQuery={PrefixOldQuery}
                            onNewQuery={PrefixTwoCharNewQuery}
                            endpointName="prefix_2char"
                            headerText="Prefix 2 Chars Queries"
                        />
                    }
                />
                <Route
                    path="/prefix-3char"
                    element={
                        <App
                            onOldQuery={PrefixOldQuery}
                            onNewQuery={PrefixThreeCharNewQuery}
                            endpointName="prefix_3char"
                            headerText="Prefix 3 Chars Queries"
                        />
                    }
                />
                <Route
                    path="/prefix-4char"
                    element={
                        <App
                            onOldQuery={PrefixOldQuery}
                            onNewQuery={PrefixFourCharNewQuery}
                            endpointName="prefix_4char"
                            headerText="Prefix 4 Chars Queries"
                        />
                    }
                />
                <Route
                    path="/prefix-5char"
                    element={
                        <App
                            onOldQuery={PrefixOldQuery}
                            onNewQuery={PrefixFiveCharNewQuery}
                            endpointName="prefix_5char"
                            headerText="Prefix 5 Chars Queries"
                        />
                    }
                />
                <Route
                    path="/prefix-6char"
                    element={
                        <App
                            onOldQuery={PrefixOldQuery}
                            onNewQuery={PrefixSixCharNewQuery}
                            endpointName="prefix_6char"
                            headerText="Prefix 6 Chars Queries"
                        />
                    }
                />
                <Route
                    path="/prefix-7char"
                    element={
                        <App
                            onOldQuery={PrefixOldQuery}
                            onNewQuery={PrefixSevenCharNewQuery}
                            endpointName="prefix_7char"
                            headerText="Prefix 7 Chars Queries"
                        />
                    }
                />
            </Routes>

        </div>
    );
};

export default Root;
