function OldQuery(searchTerm, currentPage, labelsPerPage) {
    return {
        "query": {
            "bool": {
                "should": [
                    {
                        "dis_max": {
                            "queries": [
                                {
                                    "multi_match": {
                                        "query": searchTerm,
                                        "fields": [
                                            "label.first_3_chars_convert_mapping_field",
                                            "label.first_3_chars_mapping_field"
                                        ],
                                        "type": "best_fields",
                                        "boost": 2
                                    }
                                },
                                {
                                    "multi_match": {
                                        "query": searchTerm,
                                        "fields": [
                                            "label",
                                            "label.no_numbers_mapping_field"
                                        ],
                                        "type": "best_fields",
                                        "analyzer": "multi_word_search_dateless_style_analyzer",
                                        "boost": 5
                                    }
                                },
                                {
                                    "multi_match": {
                                        "query": searchTerm,
                                        "fields": [
                                            "label",
                                            "label.no_numbers_mapping_field"
                                        ],
                                        "type": "best_fields",
                                        "analyzer": "multi_word_number_sub_search_dateless_style_analyzer"
                                    }
                                }
                            ],
                            "tie_breaker": 0.7
                        }
                    },
                    {
                        "dis_max": {
                            "queries": [
                                {
                                    "multi_match": {
                                        "query": searchTerm,
                                        "fields": [
                                            "label",
                                            "label.no_numbers_mapping_field"
                                        ],
                                        "type": "best_fields",
                                        "analyzer": "base_search_dateless_style_analyzer",
                                        "fuzziness": "1",
                                        "boost": 2.5
                                    }
                                },
                                {
                                    "multi_match": {
                                        "query": searchTerm,
                                        "fields": [
                                            "label",
                                            "label.no_numbers_mapping_field"
                                        ],
                                        "type": "best_fields",
                                        "analyzer": "base_trigram_search_dateless_style_analyzer",
                                        "boost": 2
                                    }
                                },
                                {
                                    "multi_match": {
                                        "query": searchTerm,
                                        "fields": [
                                            "label",
                                            "label.no_numbers_mapping_field"
                                        ],
                                        "type": "best_fields",
                                        "analyzer": "bigram_search_dateless_style_analyzer"
                                    }
                                },
                                {
                                    "multi_match": {
                                        "query": searchTerm,
                                        "fields": [
                                            "label.mapped_single_numbers1_mapping_field",
                                            "label.mapped_single_numbers2_mapping_field"
                                        ],
                                        "analyzer": "bigram_search_dateless_style_analyzer"
                                    }
                                },
                                {
                                    "match": {
                                        "label.mapped_single_char_mapping_field": {
                                            "query": searchTerm,
                                            "analyzer": "single_char_mapping_long_ngram_dateless_style_search_analyzer"
                                        }
                                    }
                                },
                                {
                                    "match": {
                                        "label.mapped_single_char_mapping_field": {
                                            "query": searchTerm,
                                            "analyzer": "single_char_mapping_trigram_dateless_style_search_analyzer",
                                            "boost": 0.5
                                        }
                                    }
                                },
                                {
                                    "match": {
                                        "label.mapped_multicharacters_mapping_field": {
                                            "query": searchTerm,
                                            "analyzer": "base_search_dateless_style_analyzer"
                                        }
                                    }
                                },
                                {
                                    "match": {
                                        "label.ngrams_mapping_field": {
                                            "query": searchTerm,
                                            "analyzer": "short_ngram_dateless_style_search_analyzer"
                                        }
                                    }
                                },
                                {
                                    "match": {
                                        "label": {
                                            "query": searchTerm,
                                            "analyzer": "first_last_char_dateless_style_search_analyzer"
                                        }
                                    }
                                },
                                {
                                    "match": {
                                        "label": {
                                            "query": searchTerm,
                                            "analyzer": "strip_vowels_dateless_style_search_analyzer"
                                        }
                                    }
                                }
                            ],
                            "tie_breaker": 0
                        }
                    },
                    {
                        "match": {
                            "supplier_id": {
                                "query": 19,
                                "boost": 8,
                                "lenient": true,
                                "operator": "OR",
                                "zero_terms_query": "all"
                            }
                        }
                    }
                ],
                "must": [],
                "filter": [
                    {
                        "term": {
                            "available": true
                        }
                    }
                ]
            }
        },
        "size": labelsPerPage,
        "from": (currentPage - 1) * labelsPerPage,
        "sort": [
            "_score"
        ],
        "track_total_hits": true
    };
}

export default OldQuery;